<template>
    <div class="reports">
        <dl>
        <dt><a href="#">Export to Excel</a></dt>
        <dt><a href="#">Flow Diagram</a></dt>
        <dt><a @click="openDataExtractionReport()">Data extraction report (TBD)</a></dt>
        <dt><a href="#">Audit Trail</a></dt>
        <dd><a @click="openStageHistoryByPublication()"> - Stage History by Citation</a></dd>
        <dt><a @click="openCitationsWithAnnotationsDiscrepancies()">Citations with Annotations/Discrepancies (Flagged or Not Flagged)</a></dt>
        <dt><a @click="$event => openMetricsReport()">Metrics Report</a></dt>
        <!-- <dt><a href="#" @click="openStudyMapping()">Study Mapping (Verify that there are no studies without main citation)</a></dt> -->
        <dt><a @click="$event => openPotentialDuplicates()">Potential Duplicates</a></dt>
        </dl>
    </div>
</template>

<script>
export default {
  name: 'reports',
  methods: {
    openStageHistoryByPublication: function () {
      // this.$router.push({ path: '/Secure/reports/StageHistoryByCitation' })
    },
    openCitationsWithAnnotationsDiscrepancies: function () {
      this.$router.push({ path: '/Secure/reports/CitationsWithAnnotationsDiscrepancies' })
    },
    openMetricsReport: function () {
      this.$router.push({ path: '/Secure/reports/OpenMetricsReport' })
    },
    openPotentialDuplicates: function () {
      this.$router.push({ path: '/Secure/reports/PotentialDuplicates' })
    },
    openDataExtractionReport: function () {
      console.log(0)
      this.$router.push({ path: '/Secure/reports/DataExtractionReport' })
    }
  }
}
</script>
